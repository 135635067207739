exports.components = {
  "component---src-components-templates-[news]-tsx": () => import("./../../../src/components/templates/[news].tsx" /* webpackChunkName: "component---src-components-templates-[news]-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-activities-tsx": () => import("./../../../src/pages/activities.tsx" /* webpackChunkName: "component---src-pages-activities-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-day-service-tsx": () => import("./../../../src/pages/day-service.tsx" /* webpackChunkName: "component---src-pages-day-service-tsx" */),
  "component---src-pages-donation-tsx": () => import("./../../../src/pages/donation.tsx" /* webpackChunkName: "component---src-pages-donation-tsx" */),
  "component---src-pages-hatsuratsu-club-tsx": () => import("./../../../src/pages/hatsuratsu-club.tsx" /* webpackChunkName: "component---src-pages-hatsuratsu-club-tsx" */),
  "component---src-pages-himawari-4-tsx": () => import("./../../../src/pages/himawari4.tsx" /* webpackChunkName: "component---src-pages-himawari-4-tsx" */),
  "component---src-pages-hureai-ikiiki-tsx": () => import("./../../../src/pages/hureai-ikiiki.tsx" /* webpackChunkName: "component---src-pages-hureai-ikiiki-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-introduce-tsx": () => import("./../../../src/pages/introduce.tsx" /* webpackChunkName: "component---src-pages-introduce-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-kokobari-tsx": () => import("./../../../src/pages/kokobari.tsx" /* webpackChunkName: "component---src-pages-kokobari-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-mail-1-tsx": () => import("./../../../src/pages/mail1.tsx" /* webpackChunkName: "component---src-pages-mail-1-tsx" */),
  "component---src-pages-minami-ikiiki-tsx": () => import("./../../../src/pages/minami-ikiiki.tsx" /* webpackChunkName: "component---src-pages-minami-ikiiki-tsx" */),
  "component---src-pages-minami-net-tsx": () => import("./../../../src/pages/minami-net.tsx" /* webpackChunkName: "component---src-pages-minami-net-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-pr-magazine-tsx": () => import("./../../../src/pages/pr-magazine.tsx" /* webpackChunkName: "component---src-pages-pr-magazine-tsx" */),
  "component---src-pages-sawayaka-tsx": () => import("./../../../src/pages/sawayaka.tsx" /* webpackChunkName: "component---src-pages-sawayaka-tsx" */),
  "component---src-pages-senior-tsx": () => import("./../../../src/pages/senior.tsx" /* webpackChunkName: "component---src-pages-senior-tsx" */),
  "component---src-pages-spots-tsx": () => import("./../../../src/pages/spots.tsx" /* webpackChunkName: "component---src-pages-spots-tsx" */),
  "component---src-pages-volunteer-tsx": () => import("./../../../src/pages/volunteer.tsx" /* webpackChunkName: "component---src-pages-volunteer-tsx" */),
  "component---src-pages-welfare-tsx": () => import("./../../../src/pages/welfare.tsx" /* webpackChunkName: "component---src-pages-welfare-tsx" */)
}

